.counters {
  background: radial-gradient(circle, #795548 0%, #88675a 100%);
}

.counters__title {
  color: #fff;
  font-size: 1rem;
}

.counters__number {
  color: #fff;
  font-size: 3rem;
}

/*# sourceMappingURL=counters.24be6a78.css.map */
