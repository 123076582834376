.counters {
  background: rgb(121, 85, 72);
  background: radial-gradient(circle, rgba(121, 85, 72, 1) 0%, rgba(136, 103, 90, 1) 100%);
}

.counters__title {
  font-size: 1rem;
  color: white;
}

.counters__number {
  font-size: 3rem;
  color: white;
}
